import { context } from '@webfx/core-web';
import { useAuth } from '@webfx/web-hooks';
import classnames from 'classnames';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import Menu from './Menu/Menu';

import Toggle from './Toggle';
import styles from './ViewAsSelector.module.css';

export default function ViewAsSelector() {
  const { ResizeContext } = context;
  const { user: currentUser = {}, loggedInUser = {} } = useAuth();

  const toggleLabel = React.useMemo(() => {
    if (currentUser?.userId === loggedInUser?.userId) {
      return 'View as...';
    }
    return `Viewing as ${currentUser?.firstName} ${currentUser?.lastName}`;
  }, [currentUser, loggedInUser]);

  return (
    <div className="d-flex justify-content-end pt-1">
      <Dropdown className={styles.viewAsSelector}>
        <Dropdown.Toggle as={Toggle} data-fx-name="viewAs">
          <span className="flex-grow-1 text-left" data-fx-name="currentClientProfile">
            {toggleLabel}
          </span>
        </Dropdown.Toggle>
        <ResizeContext.Provider>
          <div className=" position-relative">
            <div className={classnames('bg-danger position-absolute', styles.menuOuter)}>
              <Dropdown.Menu className={styles.menuWrapper}>
                <Menu />
              </Dropdown.Menu>
            </div>
          </div>
        </ResizeContext.Provider>
      </Dropdown>
    </div>
  );
}
